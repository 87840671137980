import { initAfterDOMLoaded } from '../utilities/initAfterDOMLoaded.js';

const seatpostCalculator = document.querySelector('.seatpost-tool');

const initSeatpostCalculator = () => {
    if (seatpostCalculator) {
        const form = seatpostCalculator.querySelector('form');
        form.addEventListener('submit', async(event) => {
            event.preventDefault();
            const formData = new FormData(form)
            const data = await callSeatposts(formData);
            
            renderResults(data);
            const resetButton = seatpostCalculator.querySelector('#calculate-another-post')
            
            resetButton.addEventListener('click', (e) => calculateAnotherPost(e))
        }
        );
    }
}

async function callSeatposts(formData) {
    try {
        const diameter = formData.get('diameter');
        const collarToSeatRail = formData.get('collarToSeatRail');
        const maxInsertion = formData.get('maxInsertion');

        const queryParams = new URLSearchParams({
            diameter,
            collarToSeatRail,
            maxInsertion
        });

        // Send to server
        const response = await fetch(`/api/seatpostfit/calc?${queryParams.toString()}`);

        // If the server returns JSON, parse it
        const result = await response.json();
        console.log("Server result:", result);
        return result
    } catch (err) {
        console.error("Error:", err);
    }
}

function renderResults(data) {
    const outputContainer = seatpostCalculator.querySelector('#output');
    outputContainer.innerHTML = '';

    const sortedData = [...data].sort((a, b) => a.OverallLength - b.OverallLength);

    // Get references to the templates
    const containerTemplate = seatpostCalculator.querySelector('#seatpost-results-template');
    const itemTemplate = seatpostCalculator.querySelector('#seatpost-results-item');

    // Clone the container template
    const containerClone = containerTemplate.content.cloneNode(true);

    // Locate the area in the container clone where individual items will go
    const itemsArea = containerClone.querySelector('.seatpost-items-area');

    // Iterate over sorted data to create individual items
    sortedData.forEach((post, index) => {
        
        const itemClone = itemTemplate.content.cloneNode(true);

        itemClone.querySelector('.seatpost-type-title').textContent = index;
        itemClone.querySelector('.seatpost-length-diameter').textContent =
            `${post.Travel}mm / ${post.Diameter} diameter`;
        itemClone.querySelector('.seatpost-length-diameter-text').textContent =
            `We think you should try out a ${post.Travel}mm seatpost with ${post.Diameter}mm diameter*
${post.FgCode} - ${post.Description}`;

        // Append the populated item to the items area
        itemsArea.appendChild(itemClone);
    });

    // Append the entire container clone to the output
    outputContainer.appendChild(containerClone);
}


function calculateAnotherPost(e) {
    e.preventDefault()
    const output = seatpostCalculator.querySelector('#output')
    output.innerHTML = ''
}

initAfterDOMLoaded(initSeatpostCalculator)